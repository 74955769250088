import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import History from '../pages/History'
import Feedback from '../pages/Feedback'
import ScheduleTask from '../pages/ScheduleTask'
import ScheduleTaskHistory from '../pages/ScheduleTaskHistory'
import AboutUs from '../pages/AboutUs'
import ContactUs from '../pages/ContactUs'
import Employee from '../pages/Employee'
import OnetimeHistory from '../components/homenew/OnetimeHistory'
import OneTimeTaskHistory from '../pages/OneTimeTaskHistory'


function PrivateRoutes() {
    return (

        <Routes >
            <Route path='' element={<Home />} />
            <Route path='history' element={<History />} />
            <Route path='onetime-task-history/:employeeId' element={<OneTimeTaskHistory />} />
            <Route path='feedback/:docId' element={<Feedback />} />
            <Route path='schedule-task' element={<ScheduleTask />} />
            <Route path='schedule-task-history' element={<Employee />} />
            <Route path='schedule-task-history/:employeeId' element={<ScheduleTaskHistory />} />
            <Route path='aboutus' element={<AboutUs />} />
            <Route path='contactus' element={<ContactUs />} />
            {/* <Route path='/plans' element={<Plans />} /> */}
            {/* <Route path='/paypalCheckout' element={<Paypal />} /> */}
        </Routes>
    )
}

export default PrivateRoutes
