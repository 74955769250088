import { Box, Grid, Paper, TextField, Typography, } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import EmplyeeList from '../components/schedule/EmplyeeList';

export default function Employee() {
    const employees = useSelector((state: any) => state.employee.data)
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    return (
        <>
            <Header />
            <EmplyeeList />
        </>
    )
}
