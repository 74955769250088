import React from 'react';
import { TextField, Button, Typography, Grid, Paper, Box, IconButton } from '@mui/material';
import { Google, Facebook, GitHub } from '@mui/icons-material';
import styled from 'styled-components';
import logo from '../Images/logo.png'
import Header from '../components/header/Header';

const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const CustomButton = styled(Button)`
  background-color: #1a73e8;
  color: white;
  &:hover {
    background-color: #135ab0;
  }
`;

const ContactUs = () => {
  return (
  <>
      <Header />

<Grid container component="main" sx={{ height: '100vh' }}>
  <Grid
    item
    xs={false}
    sm={4}
    md={7}
    sx={{
      backgroundColor: '#2C2C2C',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      p: 4,
    }}
  >
    <Box>
      <Typography component="h1" variant="h4" fontWeight="bold" gutterBottom>
        Welcome to FeedBack!
      </Typography>
      <Typography component="p" variant="body1">
ContactUs for more information
      </Typography>
    </Box>
  </Grid>
  <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Logo src={logo} alt="Logo" />
        <Typography component="h1" variant="h5" fontWeight="bold">
FeedBack
        </Typography>
      </Box>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          InputProps={{
            style: { fontSize: 14 },
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          InputProps={{
            style: { fontSize: 14 },
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="subject"
          label="Subject"
          name="subject"
          autoComplete="subject"
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          InputProps={{
            style: { fontSize: 14 },
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="message"
          label="Message"
          name="message"
          multiline
          rows={4}
          InputLabelProps={{
            style: { fontSize: 14 },
          }}
          InputProps={{
            style: { fontSize: 14 },
          }}
        />
        <CustomButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit
        </CustomButton>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            Or Contact Us Through
          </Typography>
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <IconButton>
              <Google />
            </IconButton>
            <IconButton>
              <Facebook />
            </IconButton>
            <IconButton>
              <GitHub />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  </Grid>
</Grid>
  </>
  );
};

export default ContactUs;
