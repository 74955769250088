import { Box, Grid, Paper, TextField, Typography, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { get_employees_thunk } from '../../redux/employee/employeeThunk';

export default function EmplyeeList() {
    const token = localStorage.getItem('s3WebToken');

    const employees = useSelector((state: any) => state.employee.data)
    const [searchQuery, setSearchQuery] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(get_employees_thunk({ token }));
    }, [dispatch, token]);
    const navigate = useNavigate();
    return (
        <Box mt={4} display="flex" justifyContent="center" style={{ overflowY: 'scroll' }}>
            <style>
                {`
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
body {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
`}
            </style>
            <Paper elevation={3} sx={{ p: 4, width: '80%', backgroundColor: '#f5f5f5' }}>
                <Grid container spacing={2} mb={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search by person name or email"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            sx={{ bgcolor: '#fff', borderRadius: '4px' }}
                        />
                    </Grid>

                </Grid>

                <Box mt={2} className="space-y-4">
                    {employees?.length > 0 ? (
                        employees
                            .filter((task: any) =>
                                task?.username?.toLowerCase().includes(searchQuery.toLowerCase()) || task?.email?.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .map((task: any) => (
                                <div className='flex justify-between items-center cursor-pointer' key={task._id} onClick={() => { navigate(`/schedule-task-history/${task._id}`) }}>

                                    <div className='bg-gray-200 p-[10px] rounded-lg w-full'>
                                        <div className="flex items-start">
                                            {/* <div>
                                <span className="inline-flex justify-center items-center w-6 h-6 rounded bg-green-400 text-white font-medium text-sm">
                                    Q
                                </span>
                            </div> */}

                                            <div className='flex-col justify-between w-full bg-gray-100 p-4 rounded-lg shadow-md gap-y-1'>
                                                <p className="font-semibold text-lg text-blue-500 ">
                                                    Person Name: {task?.username}
                                                </p>
                                                <p className="text-gray-600 ">
                                                    Person Email: {task?.email}
                                                </p>
                                                {/* <div className="flex justify-between text-gray-700 ">
                                            <span>Day: {task.day}</span>
                                        </div> */}

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            ))
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            {employees === null ? (
                                <></>
                                // <CircularProgress size={35} color="secondary" thickness={5} />
                            ) : (
                                <Typography variant="body2">No employee found</Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    )
}
