import React from 'react'
import RecentTask from '../components/home/RecentTask'
import Header from '../components/header/Header'
import EmplyeeList from '../components/homenew/EmployeeList'

export default function History() {
    return (
        <>
            <Header />
            <EmplyeeList />
        </>

    )
}
