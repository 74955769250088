import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { dateFilterArray, scheduleTaskStatusArray } from '../../common/common';
import { delete_employee_schedule_task_thunk, delete_schedule_task_thunk, get_employee_schedule_task_thunk } from '../../redux/scheduletask/scheduleTaskThunk';
import { clearAlerts } from '../../redux/alert/alertSlice';
import moment from 'moment-timezone';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { clearScheduleDocs } from '../../redux/scheduletask/scheduleTaskSlice';


export default function ScheduleHistory() {
    const token = localStorage.getItem('s3WebToken');
    const [searchQuery, setSearchQuery] = useState('');
    const [status, setStatus]: any = useState(null);
    const [assignedDateFilter, setAssignedDateFilter]: any = useState(null);
    const { employeeId } = useParams();

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const docs: any = useSelector((state: any) => state.scheduleTask.employeeTaskData)

    useEffect(() => {
        let statusInp = status ? status.status : scheduleTaskStatusArray[0].status
        let assignedDateFilterInp = assignedDateFilter ? assignedDateFilter.status : dateFilterArray[0].status
        dispatch(clearAlerts())
        dispatch(get_employee_schedule_task_thunk({ status: statusInp, token, createdFor: employeeId, assignedDateFilter: assignedDateFilterInp }));
    }, [status, dispatch, token, assignedDateFilter, employeeId]);


    const handleDelete = (id: any) => {
        let statusInp = status ? status.status : scheduleTaskStatusArray[0].status
        let assignedDateFilterInp = assignedDateFilter ? assignedDateFilter.status : dateFilterArray[0].status
        dispatch(clearAlerts())
        dispatch(delete_employee_schedule_task_thunk({ token, _id: id, status: statusInp, createdFor: employeeId, assignedDateFilter: assignedDateFilterInp }));
    };


    const convertUtcTimeToCountryTime = (utcTime: string, timezone: string): string => {
        console.log('utcTime', utcTime)
        console.log('timeZone', timezone)
        if (!utcTime || !timezone) {
            throw new Error("Both utcTime and timezone must be provided");
        }

        const countryTime = moment.utc(utcTime).tz(timezone);
        return countryTime.format('h:mm A'); // Format to get output like '8:00 AM'
    };

    return (
        <Box mt={4} display="flex" justifyContent="center" style={{ overflowY: 'scroll' }}>
            <style>
                {`
          /* Hide scrollbar for Chrome, Safari and Opera */
          ::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge, and Firefox */
          body {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
        `}
            </style>
            <Paper elevation={3} sx={{ p: 4, width: '80%', backgroundColor: '#f5f5f5' }}>
                <Grid container spacing={2} mb={2} alignItems="center" className='space-x-4'>
                    {/* Back button on the left */}
                    <Grid item xs={2} sm={1}>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={() => {
                                dispatch(clearScheduleDocs())
                                navigate('/schedule-task-history');
                            }}
                            sx={{ bgcolor: 'primary.main' }} // Customize as needed
                        >
                            Back
                        </Button>
                    </Grid>

                    {/* Search field centered */}
                    <Grid item xs={8} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search by task title or description"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            sx={{ bgcolor: '#fff', borderRadius: '4px' }}
                        />
                    </Grid>

                    {/* Placeholder Grid item to balance the layout */}
                    <Grid item xs={2} sm={2} />
                </Grid>
                <Grid container spacing={2} mb={2} justifyContent="center" alignItems="center">

                    <Grid item sm={6} >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Select A Filter</InputLabel>
                            <Select
                                value={assignedDateFilter ? assignedDateFilter.id : dateFilterArray[0].id}
                                onChange={(e) => {
                                    const id = e.target.value;
                                    const dateFilter = dateFilterArray.find((p: any) => p.id === id);
                                    setAssignedDateFilter(dateFilter || null); // Update the entire person object or reset if none selected
                                }}
                                label="Select A Status"
                            >

                                {dateFilterArray?.length > 0 && dateFilterArray.map((p: any) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.status} {/* Display folder name */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Select A Status</InputLabel>
                            <Select
                                value={status ? status.id : scheduleTaskStatusArray[0].id}
                                onChange={(e) => {
                                    const id = e.target.value;
                                    const selectedStatus = scheduleTaskStatusArray.find((p: any) => p.id === id);
                                    setStatus(selectedStatus || null); // Update the entire person object or reset if none selected
                                }}
                                label="Select A Status"
                            >

                                {scheduleTaskStatusArray?.length > 0 && scheduleTaskStatusArray.map((p: any) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.status} {/* Display folder name */}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Box mt={2} className="space-y-4">
                    {docs?.length > 0 ? (
                        docs
                            .filter((task: any) =>
                                task.question?.toLowerCase().includes(searchQuery.toLowerCase()) || task.title?.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .map((task: any) => (
                                <div className='flex justify-between items-center'>
                                    <div className='bg-gray-200 p-4 rounded-lg w-full'>
                                        <div className="flex items-center space-x-2 mb-4">
                                            <span className="inline-flex justify-center items-center w-6 h-6 rounded-full bg-green-500 text-white font-medium text-sm">
                                                Q
                                            </span>
                                            <p className="font-bold text-md">
                                                {task?.title || task?.question}
                                            </p>
                                        </div>

                                        <div className='flex justify-between items-center mb-2'>
                                            <p className="text-gray-600">
                                                {status?.status === "Assigned" || !status ?
                                                    `${convertUtcTimeToCountryTime(task?.countryTime, task?.createdFor?.timeZone)} - ${task.day} - ${task?.assignedDate ? task?.assignedDate?.split("T")[0] : ""} - ${task.deadlineHour ? `Expire in ${task.deadlineHour} hour` : ''}`
                                                    :
                                                    `${convertUtcTimeToCountryTime(task?.countryTime, task?.createdFor?.timeZone)} - ${task.day} - ${task?.assignedDate ? task?.assignedDate?.split("T")[0] : ""} - ${task.deadlineHour ? `Deadline was ${task.deadlineHour} hour` : ''}`
                                                }
                                            </p>

                                            <p className="text-gray-800">
                                                Assigned To: {task?.createdFor?.username} {`(${task?.createdFor?.country})`}
                                            </p>
                                        </div>

                                        <p className="text-sm text-gray-600 my-4">
                                            {task?.question}
                                        </p>

                                        <div className="flex items-center space-x-2">
                                            <span className="inline-flex justify-center items-center w-6 h-6 rounded-full bg-blue-300 text-gray-800 font-medium text-sm">
                                                A
                                            </span>
                                            {task?.answerType === 0 ?
                                                <p className="font-semibold text-gray-800">
                                                    {task?.answer}
                                                </p>
                                                : <a href={`https://docs.google.com/document/d/${task?.answer}`} target="_blank" rel="noopener noreferrer" className="font-semibold text-blue-800 underline">
                                                    Click to view doc
                                                </a>
                                            }
                                        </div>
                                    </div>
                                    <div >
                                        <IconButton onClick={(e) => handleDelete(task._id)} className="text-black hover:text-red-500 focus:text-red-500">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>


                            ))
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            {docs === null ? (
                                <></>
                                // <CircularProgress size={35} color="secondary" thickness={5} />
                            ) : (
                                <Typography variant="body2">No tasks found</Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    )
}
