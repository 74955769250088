import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get_feedback_thunk, update_feedback_thunk } from '../../redux/doc/docThunk'
import { clearAlerts } from '../../redux/alert/alertSlice'
import { get_prompt_thunk, update_prompt_thunk } from '../../redux/auth/authThunk'
import { uploadAudio } from '../../utils/UploadData'
// import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
const OPENAI_KEY = process.env.REACT_APP_OPENAI_KEY
// const WHAT_KEY = process.env.REACT_APP_WHAT
const KEY_QWE = process.env.REACT_APP_KEY_QWE

function RightSide() {
    const token = localStorage.getItem('s3WebToken')
    const initialText = useSelector((state: any) => state.doc.feedback?.text) || ''
    const initialCorrectGrammarText = useSelector((state: any) => state.doc.feedback?.correctGrammarText) || ''
    const initialPercentage = useSelector((state: any) => state.doc.feedback?.percentage) || 0
    const userDetails = useSelector((state: any) => state.auth.user)

    const [prompt, setPrompt] = useState("")
    const [key_qwe, setWhat] = useState('qwe')
    const [text, setText] = useState(initialText);
    const [prompt1, setPROMPT1] = useState('');
    const [audioBlobState, setAudioBlobState]: any = useState(null);
    // const [prompt1, setPROMPT1] = useState('rewrite the given text with only grammar correct');
    const [correctGrammarText, setCorrectGrammarText] = useState(initialCorrectGrammarText);
    const [inputText, setInputText] = useState(text);
    const [inputCorrectGrammarText, setInputCorrectGrammarText] = useState(correctGrammarText);
    const [percentage, setPercentage] = useState(initialPercentage);
    // new state
    const [copyMessage, setCopyMessage] = useState('');
    const [grammarCopyMessage, setGrammarCopyMessage] = useState('');
    const [onlyTranscriptMessage, setOnlyTranscriptMessage] = useState(false);
    const onlyTranscriptMessageRef = useRef(onlyTranscriptMessage);
    // const [isBlocked, setIsBlocked] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(0);
    const timerRef = useRef(timer);
    const maxRecordingTime = 300; // 5 minutes in seconds
    const mediaRecorderRef: any = useRef(null);
    const audioChunksRef: any = useRef([]);
    const dispatch = useDispatch();
    const { docId } = useParams();

    // let tempTime = 0
    // const [textLoading, setTextLoading] = useState(false)
    useEffect(() => {
        setPercentage(initialPercentage || 0);
        setText(initialText || '');
        setCorrectGrammarText(initialCorrectGrammarText || '');
        setPrompt(userDetails?.prompt?.prompt1 || '');
        setPROMPT1(userDetails?.prompt?.prompt2 || '');
    }, [initialPercentage, initialText, initialCorrectGrammarText, userDetails]);

    useEffect(() => {
        dispatch(get_prompt_thunk({ token }));
    }, [dispatch, token]);

    useEffect(() => {
        dispatch(get_feedback_thunk({ token, docId }));
    }, [dispatch, token, docId]);

    useEffect(() => {
        setInputText(text);
    }, [text]);

    useEffect(() => {
        setInputCorrectGrammarText(correctGrammarText);
    }, [correctGrammarText]);

    useEffect(() => {
        onlyTranscriptMessageRef.current = onlyTranscriptMessage;
    }, [onlyTranscriptMessage]);

    const transcribeAudio = useCallback(async (audioBlob: any, timeParameter: any) => {
        // formData.append('file', audioBlob, 'recording.mp3');

        const formData = new FormData();
        formData.append('file', audioBlob, 'recording.wav');


        let model = 'whisper-1';
        formData.append('model', model)
        formData.append('prompt', prompt)




        try {
            const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${OPENAI_KEY}`, // Replace with your API key
                    // 'Content-Type': 'multipart/form-data'
                },
                body: formData
            });

            const whisperResult = await response.json();
            if (whisperResult.text) {
                setText(whisperResult?.text)
                setCorrectGrammarText('')
                setTimer(0)
                if (!onlyTranscriptMessageRef.current) {

                    // setText(whisperResult.text); // Update your UI or state as needed
                    const chatResponse = await fetch('https://api.openai.com/v1/chat/completions', {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${OPENAI_KEY}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            model: "gpt-4o",
                            messages: [
                                // { "role": "system", "content": "Answer step by step and with examples with sample data" },
                                { "role": "system", "content": prompt1 },
                                { "role": "user", "content": whisperResult.text }
                            ]
                        })
                    });

                    const chatResult = await chatResponse.json();
                    setCorrectGrammarText(chatResult?.choices[0]?.message?.content)
                }
                else {
                    setOnlyTranscriptMessage(false)
                }
            } else {
                console.log('No transcription available');
            }
            // The transcript will be in the result
        } catch (error) {
            console.error('There was an error calling the Whisper API', error);
            alert('There was an error calling the Whisper API')
        }
    }, [prompt, prompt1]);

    const startRecording = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (e: any) => {
                audioChunksRef.current.push(e.data);
            };

            mediaRecorderRef.current.onstop = async () => {
                // const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                console.log('audioBlob', audioBlob)
                await transcribeAudio(audioBlob, timerRef.current);
                setAudioBlobState(audioBlob);
                audioChunksRef.current = [];
            };

            mediaRecorderRef.current.start(1000);
            setIsRecording(true);
        } catch (err: any) {
            console.log('An error occurred during recording:', err.message);
            alert(err.message)
        }

        // Start recording logic
    }, [transcribeAudio,]);

    const stopRecording = useCallback(() => {
        // Stop recording logic
        mediaRecorderRef.current?.stop();
        const stream = mediaRecorderRef.current?.stream;
        if (stream) {
            stream.getTracks().forEach((track: any) => track.stop());
        }
        setIsRecording(false);

    }, []);

    useEffect(() => {
        let interval: any = null;

        if (isRecording) {
            interval = setInterval(() => {
                // tempTime = timer
                setTimer(prevTimer => {
                    if (prevTimer < maxRecordingTime) {
                        timerRef.current = prevTimer + 1;
                        return prevTimer + 1;
                    } else {
                        stopRecording();
                        timerRef.current = prevTimer;
                        return prevTimer;
                    }
                    // const newTimer = prevTimer < maxRecordingTime ? prevTimer + 1 : prevTimer;
                    // timerRef.current = newTimer; // Update the ref
                    // return newTimer;
                });
            }, 1000);
        } else {
            clearInterval(interval);
            // setTimer(0)

            // Reset timer when not recording
        }

        return () => clearInterval(interval);
    }, [isRecording, maxRecordingTime, stopRecording]);

    const toggleRecording = () => {
        if (!isRecording || timer >= maxRecordingTime) {
            startRecording();
        } else {
            stopRecording();
        }
    };

    const handlePercentageChange = (e: any) => {
        const value = parseInt(e.target.value, 10);
        setPercentage(value);
    };





    const copyToClipboard = (textToCopy: any) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopyMessage('suceessfully copied!');
            setTimeout(() => setCopyMessage(''), 2000);
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };
    const copyToClipboardGrammar = (textToCopy: any) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setGrammarCopyMessage('suceessfully copied!');
            setTimeout(() => setGrammarCopyMessage(''), 2000);
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (document.activeElement instanceof HTMLElement && document.activeElement.tagName === 'INPUT') {
                return;
            }
            if (document.activeElement instanceof HTMLElement && document.activeElement.tagName === 'TEXTAREA') {
                return;
            }
            if (event.key === 'a' || event.key === 'A') {
                startRecording();

            } else if (event.key === 'S' || event.key === 's' || event.key === 'space') {
                stopRecording();

            }
            else if (event.key === 'f' || event.key === 'F') {
                copyToClipboard(text);
            } else if (event.key === 'g' || event.key === 'G') {
                copyToClipboardGrammar(correctGrammarText);
            } else if (event.key === 'z' || event.key === 'Z') {
                setOnlyTranscriptMessage(true);
                stopRecording();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [text, correctGrammarText, isRecording, startRecording, stopRecording]);



    const handleSubmitFeedback = async (e: any) => {
        e.preventDefault();
        dispatch(clearAlerts())

        const formData = new FormData();
        console.log('audioBlobState', audioBlobState)
        let audio = '';

        if (audioBlobState) {

            formData.append('audio', audioBlobState, 'recording.wav');

            const responseData = await uploadAudio(formData, dispatch);
            if (responseData.data.audioUrl) {
                audio = responseData.data.audioUrl
            }
        }


        let data = {
            feedback: {
                percentage: percentage,
                text: inputText,
                correctGrammarText: inputCorrectGrammarText,
                audio: audio
            }
        }
        dispatch(update_feedback_thunk({ data: data, docId: docId, token: token }))
    }

    const handlePromptSubmit = (e: any) => {
        e.preventDefault()
        dispatch(clearAlerts())
        let data = {
            prompt: {
                prompt1: prompt,
                prompt2: prompt1
            }

        }
        dispatch(update_prompt_thunk({ data: data, token: token }))
    }


    return (
        <>
            {/* <h1 className='mx-auto text-center max-w-2xl mb-4 text-6xl font-extrabold leading-none tracking-tight md:text-3xl xl:text-4xl text-purple-700 mt-[30px]'>RepeatMe.us</h1> */}
            {/* <h2 className='mx-auto text-center max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-3xl xl:text-2xl text-purple-700 mt-[30px]'>press A to start</h2> */}
            {/* <h2 className='mx-auto text-center max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-3xl xl:text-2xl text-purple-700 mt-[30px]'>press S to stop</h2> */}
            {/* <h2 className='mx-auto text-center max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-3xl xl:text-2xl text-purple-700 mt-[30px]'>press D to pause/resume</h2> */}




            <div className='flex flex-col gap-[20px] justify-center items-center  w-full'>
                <div className='flex flex-col bg-gray-100 h-[45vh] sm:flex-row gap-5 justify-center items-center w-full px-4 py-5'>
                    <div className='flex flex-col items-center w-full'>
                        <h1 className="text-md font-semibold mb-4">Give Your Feedback</h1>
                        <div className="flex items-center justify-center space-x-4">
                            <input
                                type="number"
                                min="0"
                                max="100"
                                value={percentage}
                                onChange={handlePercentageChange}
                                className="border border-gray-300 rounded-md px-3 py-2 w-24 text-center focus:outline-none"
                            />
                            <span>%</span>
                        </div>
                        <h1 className='text-md  font-semibold my-4'>FeedBack</h1>
                        <div className='flex flex-row gap-5 w-full '>
                            <textarea
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                                className='outline-none bg-white w-full sm:max-w-md p-5 rounded-lg text-black shadow-xl no-scrollbar'
                                style={{ minHeight: '150px', maxWidth: '500px' }}
                            />
                            <textarea
                                value={inputCorrectGrammarText}
                                onChange={(e) => setInputCorrectGrammarText(e.target.value)}
                                className='outline-none bg-white w-full sm:max-w-md p-5 rounded-lg text-black shadow-xl no-scrollbar'
                                style={{ minHeight: '150px', maxWidth: '500px' }}
                            />
                        </div>

                    </div>

                </div>
                {copyMessage && (
                    <div style={{ position: 'fixed', top: 10, right: 10, backgroundColor: 'rgb(126 34 206)', padding: '10px', borderRadius: '5px', color: 'white' }}>
                        Text Copied to clipboard
                    </div>
                )}
                {grammarCopyMessage && (
                    <div style={{ position: 'fixed', top: 10, right: 10, backgroundColor: 'rgb(126 34 206)', padding: '10px', borderRadius: '5px', color: 'white' }}>
                        Correct Grammer Text Copied to clipboard
                    </div>
                )}
                <div className='bg-white max-w-[800px] py-[10px] px-[20px] rounded-lg flex justify-center items-center flex-col gap-5 ' style={{ boxShadow: "20px 20px 60px #00000041,inset -20px -20px 60px #ffffff40" }}>
                    {key_qwe === KEY_QWE && (
                        <div className='flex justify-center items-center gap-5 outline-none border-none' >
                            <button className='bg-white  outline-none rounded-full shadow-lg relative flex  gap-5 p-5' onClick={toggleRecording}>

                                <svg className='z-[999] outline-none'
                                    fill='black'
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 512 512"
                                >
                                    <path d="M439.5 236c0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 70-64 126.9-142.7 126.9-78.7 0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 86.2 71.5 157.4 163.1 166.7v57.5H212c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4h88c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z" />
                                    <path d="M256 323.5c51 0 92.3-41.3 92.3-92.3V103.3c0-51-41.3-92.3-92.3-92.3s-92.3 41.3-92.3 92.3v127.9c0 51 41.3 92.3 92.3 92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3 23.5 52.3 52.3v127.9c0 28.8-23.5 52.3-52.3 52.3s-52.3-23.5-52.3-52.3V103.3z" />
                                </svg>
                            </button>
                            {isRecording && <span className=" text-black bg-white px-3 py-[7px] shadow-lg rounded-md">
                                {`${Math.floor(timer / 60).toString().padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
                            </span>}
                        </div>
                    )}
                    {/* <form onSubmit={handleSubmit2} className='w-[350px]'> */}
                    <form className='w-[350px]'>
                        <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative">

                            <input type="search" autoComplete='off' autoCorrect='off' id="search" className="block w-full py-4 pl-4 pr-[90px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white outline-none dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type..." onChange={(e) => setWhat(e.target.value)} value={key_qwe} />
                            <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">Submit</button>
                        </div>
                    </form>
                    {/* <form onSubmit={handleSubmit} className='w-[350px]'> */}
                    <form className='w-[350px]'>
                        <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative">

                            <input type="search" autoComplete='off' autoCorrect='off' id="search" className="block w-full py-4 pl-4 pr-[90px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white outline-none dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type..." onChange={(e) => setPrompt(e.target.value)} value={prompt} />
                            <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" onClick={(e) => handlePromptSubmit(e)}>Submit</button>
                        </div>
                    </form>
                    {/* <form onSubmit={handleSubmit3} className='w-[350px]'> */}
                    <form className='w-[350px]'>
                        <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative">

                            <input type="search" autoComplete='off' autoCorrect='off' id="search" className="block w-full py-4 pl-4 pr-[90px] text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white outline-none dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type..." onChange={(e) => setPROMPT1(e.target.value)} value={prompt1} />
                            <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" onClick={(e) => handlePromptSubmit(e)}>Submit</button>
                        </div>
                    </form>
                </div>
                <button className='bg-[blue] py-[10px] px-[20px] rounded-md text-white' onClick={handleSubmitFeedback}>Save
                </button>
            </div>


        </>

    )
}

export default RightSide;