import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import logo1 from '../Images/feedback.png'; 
import logo2 from '../Images/imageone.png';
import Footer from './Footer';
import Header from '../components/header/Header';

const AboutUs = () => {
  return (

    <div>
                <Header />

      <Container maxWidth="lg" className="py-10">
         <Typography variant="h2" component="h1" className="text-center font-bold mb-16">
        About Us
      </Typography>

      <Box className="flex flex-col lg:flex-row items-center mb-16">
        <Box className=" px-5">
          <Typography variant="body1" component="p" className="mb-12 text-gray-700 text-center lg:text-left">
            Welcome to Feedback Website! Our mission is to streamline the task assignment and feedback process for your team. We are committed to providing a platform where you can easily assign tasks, set deadlines, and track progress all in one place. Our team is dedicated to creating the best tools to enhance your productivity and collaboration. At Feedback Website, we believe that effective feedback is the cornerstone of successful projects and satisfied teams. Join us on our journey to make task management seamless and efficient.
          </Typography>
        </Box>
      </Box>
      <Box className="flex flex-col lg:flex-row items-center mb-16">
      
        <Box className="lg:w-1/2 px-5">
          <Typography variant="h4" gutterBottom className="font-bold text-center lg:text-left">
            Our Journey
          </Typography>
          <Typography variant="body1" className="text-gray-700 text-center lg:text-left">
            Our feedback platform is designed to bridge the gap between task assignment and completion, providing a seamless workflow for teams of all sizes. From project inception to final delivery, we ensure that every step is meticulously tracked and managed, promoting efficiency and transparency.
          </Typography>
        </Box>
        <Box className="lg:w-1/2 px-5 mt-10 lg:mt-0 flex justify-center">
          <img src={logo1} alt="Company Logo" className="w-2/3 md:w-1/2 lg:w-1/3" />
        </Box>
      </Box>

      <Box className="flex flex-col lg:flex-row items-center mb-16">
        <Box className="lg:w-1/2 px-5 order-last lg:order-first mt-10 lg:mt-0 flex justify-center">
          <img src={logo2} alt="Company Logo" className="w-2/3 md:w-1/2 lg:w-1/3" />
        </Box>
        <Box className="lg:w-1/2 px-5">
          <Typography variant="h4" gutterBottom className="font-bold text-center lg:text-left">
            Our Vision and Mission
          </Typography>
          <Typography variant="body1" className="text-gray-700 text-center lg:text-left">
            Our vision is to revolutionize the way feedback is handled, ensuring that every voice is heard and every suggestion is implemented. Our mission is to provide a user-friendly platform that facilitates effective communication, timely task completion, and continuous improvement for all stakeholders.
          </Typography>
        </Box>
      </Box>

      <Box className="text-center py-10 bg-gray-100 rounded-lg shadow-lg">
        <Typography variant="h4" gutterBottom className="font-bold">
          About Our Company
        </Typography>
        <Typography variant="body1" className="text-gray-700 mx-auto ">
          Founded in 2024, our company has grown from a small startup to a leading provider of feedback solutions. We pride ourselves on our commitment to innovation, customer satisfaction, and continuous improvement. Our team is dedicated to helping you achieve your goals and exceed your expectations.
        </Typography>
      </Box>
    </Container>
    <Footer/>
    </div>
  );
};

export default AboutUs;
