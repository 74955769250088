import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import alertReducer from './alert/alertSlice';
import docReducer from './doc/docSlice';
import folderReducer from './folder/folderSlice';
import authReducer from './auth/authSlice';
import emailReducer from './email/emailSlice';
import employeeReducer from './employee/employeeSlice';
import scheduleTaskReducer from './scheduletask/scheduleTaskSlice';
import oneTimeTaskReducer from './onetimetask/oneTimeTaskSlice';



const store = configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
        doc: docReducer,
        folder: folderReducer,
        email: emailReducer,
        employee: employeeReducer,
        scheduleTask: scheduleTaskReducer,
        onetimeTask: oneTimeTaskReducer

    },
});

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppDispatch = () => useDispatch();
export default store;