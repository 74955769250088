import React from 'react'
import RightSide from '../components/feedback/RightSide'
import LeftSide from '../components/feedback/LeftSide'

export default function Feedback() {
    return (
        <div className='flex flex-row w-[100vw] scol'>
            <div className='w-1/2'>
                <LeftSide />
            </div>
            <div className='w-1/2'>
                <RightSide />
            </div>
        </div>
    )
}
