import React from 'react';
import '../../styles/loader.css';

export default function Loader({ fullscreen = true }) {
    const overlayClass = fullscreen ? "loader-overlay" : "loader-inline";
    return (
        <div className={overlayClass}>
            <div className="lds-ring">
                <div></div><div></div><div></div><div></div>
            </div>
        </div>
    );
}
