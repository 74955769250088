import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataAPI, putDataAPI } from "../../utils/FetchData";
import { setErrorAlert, setLoader, setSuccessAlert } from "../alert/alertSlice";

export const google_login_thunk = createAsyncThunk('auth/google_auth', async (tempData, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const google_login_thunk_res = await getDataAPI('auth/google_auth', {});
        thunkAPI.dispatch(setSuccessAlert(google_login_thunk_res?.data?.message))

        // localStorage.setItem('s3WebToken', JSON.stringify(google_login_thunk_res?.data?.data?.token));
        // localStorage.setItem('s3WebUser', JSON.stringify(google_login_thunk_res?.data?.data?.response));

        return google_login_thunk_res.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})


export const logout_thunk: any = createAsyncThunk('auth/logout', async (tempData, thunkAPI) => {
    try {
        localStorage.removeItem('s3WebToken')
        localStorage.removeItem('s3WebUser')
        window.location.href = "/"
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const get_prompt_thunk: any = createAsyncThunk('admin/userActivity/getPrompt', async (tempData: any, thunkAPI) => {
    try {
        const response = await getDataAPI(`admin/userActivity/getPrompt`, tempData?.token);
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const update_prompt_thunk: any = createAsyncThunk('admin/userActivity/updatePrompt', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const response = await putDataAPI(`admin/userActivity/updatePrompt`, tempData.data, tempData?.token);
        await thunkAPI.dispatch(get_prompt_thunk({ token: tempData.token }))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'pPrompt saved successfully'));
        thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})