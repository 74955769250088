import React from 'react'
import Header from '../components/header/Header'
import ScheduleHistory from '../components/schedule/ScheduleHistory'
import OnetimeHistory from '../components/homenew/OnetimeHistory'

export default function OneTimeTaskHistory() {
    return (
        <>
            <Header />
            <OnetimeHistory />
        </>
    )
}
