import { createSlice } from "@reduxjs/toolkit";
import { create_onetime_task_thunk, get_employee_onetime_task_thunk } from "./oneTimeTaskThunk";

const initialState = {
    onetimetaskData: null,
    error: null,
    loading: false,
}

const createScheduleTaskCases = (builder: any) => {
    builder.addCase(create_onetime_task_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(create_onetime_task_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
    }).addCase(create_onetime_task_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}

// const getScheuldeTasksCases = (builder: any) => {
//     builder.addCase(get_schedule_task_thunk.pending, (state: any) => {
//         state.loading = true;
//         state.error = null;
//     }).addCase(get_schedule_task_thunk.fulfilled, (state: any, action: any) => {
//         state.loading = false;
//         state.scheduleTaskData = action.payload.data.response
//     }).addCase(get_schedule_task_thunk.rejected, (state: any, action: any) => {
//         state.loading = false;
//         state.error = action.payload || 'Redux Toolkit Error'
//     })
// }

const getEmployeeOnetimeTasksCases = (builder: any) => {
    builder.addCase(get_employee_onetime_task_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(get_employee_onetime_task_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.onetimetaskData = action.payload.data.response
    }).addCase(get_employee_onetime_task_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}
const alertSlice = createSlice({
    name: "onetimeTask",
    initialState,
    reducers: {
        clearOneTimeDocs: (state) => {
            state.onetimetaskData = null;
            state.error = null;
            state.loading = false
        },
    },
    extraReducers: (builder) => {
        createScheduleTaskCases(builder)
        // getScheuldeTasksCases(builder)
        getEmployeeOnetimeTasksCases(builder)
    }
})

export const { clearOneTimeDocs } = alertSlice.actions;

export default alertSlice.reducer;
