import React from 'react'
import Header from '../components/header/Header'
import ScheduleHistory from '../components/schedule/ScheduleHistory'

export default function ScheduleTaskHistory() {
    return (
        <>
            <Header />
            <ScheduleHistory />
        </>
    )
}
