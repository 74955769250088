import { createAsyncThunk } from "@reduxjs/toolkit";
import { setErrorAlert, setLoader, setSuccessAlert } from "../alert/alertSlice";
import { getDataAPI, postDataAPI } from "../../utils/FetchData";


export const get_employees_thunk: any = createAsyncThunk('admin/employee/get_employee_list', async (tempData: any, thunkAPI) => {
    try {
        const response = await getDataAPI(`admin/employee/get_employee_list`, tempData?.token);
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

