import React from 'react';
import { Container, Typography, Box, Grid, List, ListItem, ListItemText, Link, InputAdornment, IconButton, TextField } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
  return (
    <Box bgcolor="black" color="white" py={10} px={6} md={{ py: 12, px: 12 }}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              FeedBack
            </Typography>
            <Typography variant="body1" paragraph>
              Welcome to Feedback Website! Our mission is to streamline the task assignment and feedback process for your team.
              We are committed to providing a platform where you can easily assign tasks, set deadlines, and track progress all in one place.
              Our team is dedicated to creating the best tools to enhance your productivity and collaboration.
              At Feedback Website, we believe that effective feedback is the cornerstone of successful projects and satisfied teams.
              Join us in our journey to make task management seamless and efficient.
            </Typography>
          </Grid>
          
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" gutterBottom>
              CUSTOMER CARE
            </Typography>
            <List component="ul" dense>
              <ListItem>
                <ListItemText primary="FAQs" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Accessibility Statement" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Terms of Service" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Privacy Policy" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Contact Us" />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" gutterBottom>
              HELP & SUPPORT
            </Typography>
            <List component="ul" dense>
              <ListItem>
                <ListItemText primary="Help Center" />
              </ListItem>
              <ListItem>
                <ListItemText primary="How to use" />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" gutterBottom>
              CONTACT US
            </Typography>
            <Typography variant="body2">
              <Link href="tel:+999-999-9999" color="inherit">
                Phone: 999-999-9999
              </Link>
            </Typography>
            <Typography variant="h6" gutterBottom style={{ marginTop: '1rem' }}>
              FOLLOW US
            </Typography>
            <Box display="flex" gap={2}>
              <InstagramIcon style={{ fontSize: 32 }} />
              <FacebookIcon style={{ fontSize: 32 }} />
              <TwitterIcon style={{ fontSize: 32 }} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>
         JOIN US WITH EMAIL
            </Typography>
            <Box mt={2} display="flex" border={1} borderColor="white" alignItems="center" borderRadius={0} p={0}>
  <TextField
    variant="outlined"
    placeholder="Enter your email"
    fullWidth
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton>
            <EmailIcon style={{ fontSize: 32, color: 'white' }} />
          </IconButton>
        </InputAdornment>
      ),
      style: { color: 'white' }
    }}
  />
</Box>

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
