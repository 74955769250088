import { createSlice } from "@reduxjs/toolkit";
import { create_doc_thunk, delete_doc_thunk, get_docs_thunk, get_feedback_thunk, update_feedback_thunk } from './docThunk'
import { setLoader } from "../alert/alertSlice";

const initialState = {
    data: null,
    error: null,
    loading: false,
    feedback: null
}

const createDocCases = (builder: any) => {
    builder.addCase(create_doc_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(create_doc_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
    }).addCase(create_doc_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}

const getDocCases = (builder: any) => {
    builder.addCase(get_docs_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(get_docs_thunk.fulfilled, (state: any, action: any) => {
        state.data = action.payload.data.response;
        state.loading = false;
    }).addCase(get_docs_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}

const deleteDocCases = (builder: any) => {
    builder.addCase(delete_doc_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(delete_doc_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
    }).addCase(delete_doc_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}

const getFeedbackCases = (builder: any) => {
    builder.addCase(get_feedback_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(get_feedback_thunk.fulfilled, (state: any, action: any) => {
        state.feedback = action.payload.data.response.feedback;
        state.loading = false;
    }).addCase(get_feedback_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}

const updateFeedbackCases = (builder: any) => {
    builder.addCase(update_feedback_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(update_feedback_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
    }).addCase(update_feedback_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}

const alertSlice = createSlice({
    name: "doc",
    initialState,
    reducers: {
        clearDocs: (state) => {
            state.data = null;
            state.error = null;
            state.loading = false
        },
        setLoader: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
        createDocCases(builder)
        getDocCases(builder)
        deleteDocCases(builder)
        getFeedbackCases(builder)
        updateFeedbackCases(builder)
    }
})

export const { clearDocs } = alertSlice.actions;

export default alertSlice.reducer;
