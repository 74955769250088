import { createSlice } from "@reduxjs/toolkit";
import { get_prompt_thunk, google_login_thunk, update_prompt_thunk } from "./authThunk";

const initialState = {
    loading: false,
    user: null,
    error: null,
    isLogin: false
}

const loginCases = (builder: any) => {
    builder.addCase(google_login_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(google_login_thunk.fulfilled, (state: any, action: any) => {
        // state.user = action.payload.data.response;
        state.isLogin = true
        state.loading = false;
    }).addCase(google_login_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Login Cases Error'
    })
}

const getPromptCases = (builder: any) => {
    builder.addCase(get_prompt_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(get_prompt_thunk.fulfilled, (state: any, action: any) => {
        state.user = action.payload.data.response;
        state.loading = false;
    }).addCase(get_prompt_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Get Prompt Cases Error'
    })
}

const updatePromptCases = (builder: any) => {
    builder.addCase(update_prompt_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(update_prompt_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
    }).addCase(update_prompt_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Update Prompt Cases Error'
    })
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null
        }
    },
    extraReducers: (builder) => {
        loginCases(builder)
        getPromptCases(builder)
        updatePromptCases(builder)
    }
})

export const { clearError } = authSlice.actions;

export default authSlice.reducer;