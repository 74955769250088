import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from '../pages/Login'



function AuthRoutes() {
    return (

        <Routes >
            <Route path='' element={<Login />} />
            {/* <Route path='/plans' element={<Plans />} /> */}
            {/* <Route path='/paypalCheckout' element={<Paypal />} /> */}
        </Routes>
    )
}

export default AuthRoutes
