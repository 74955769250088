import { useParams } from 'react-router-dom';

export default function LeftSide() {
    const { docId } = useParams();

    return (
        <div>
            <iframe src={`https://docs.google.com/document/d/${docId}`} style={{ width: "100%", height: "100vh" }} title={`Document ${docId}`}>
            </iframe>
        </div>
    );
}   
