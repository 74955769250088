import { createAsyncThunk } from "@reduxjs/toolkit";
import { setErrorAlert, setLoader, setSuccessAlert } from "../alert/alertSlice";
import { getDataAPI, postDataAPI } from "../../utils/FetchData";

export const create_folder_thunk: any = createAsyncThunk('admin/folder/create_folder', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const body = { ...tempData };
        delete body.token;
        let response = await postDataAPI('admin/folder/create_folder', body, tempData.token);
        thunkAPI.dispatch(get_folders_thunk({ token: tempData.token }))
        thunkAPI.dispatch(setLoader(false))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'Folder created successfully'));
        return response?.data?.data?.response
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const get_folders_thunk: any = createAsyncThunk('admin/folder/get_folder_list', async (tempData: any, thunkAPI) => {
    try {
        const response = await getDataAPI(`admin/folder/get_folder_list`, tempData?.token);
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})


