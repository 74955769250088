import React from 'react'
import { BASE_URL } from '../utils/FetchData';

export default function Login() {

    const handleLogin = () => {
        window.location.href = `${BASE_URL}/auth/google_auth`;
    };
    return (
        <div className="App">
            <header className="App-header">
                <button onClick={handleLogin}>Login with Google</button>
            </header>
        </div>
    )
}
