import React, { useEffect, useState } from 'react'
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { delete_doc_thunk, get_docs_thunk } from '../../redux/doc/docThunk';
import { Link } from 'react-router-dom';
import { scheduleTaskStatusArray } from '../../common/common';
import { delete_schedule_task_thunk, get_schedule_task_thunk } from '../../redux/scheduletask/scheduleTaskThunk';
import { clearAlerts } from '../../redux/alert/alertSlice';
import moment from 'moment-timezone';


export default function ScheduleTaskComponent() {
    const token = localStorage.getItem('s3WebToken');
    const [searchQuery, setSearchQuery] = useState('');
    const [status, setStatus]: any = useState(null);


    const dispatch = useDispatch()
    const docs: any = useSelector((state: any) => state.scheduleTask.scheduleTaskData)

    console.log('docs', docs)
    useEffect(() => {
        let statusInp = status ? status.status : scheduleTaskStatusArray[0].status
        dispatch(clearAlerts())
        dispatch(get_schedule_task_thunk({ token }))
    }, [status, dispatch, token]);


    const handleDelete = (id: any) => {
        let statusInp = status ? status.status : scheduleTaskStatusArray[0].status
        dispatch(clearAlerts())
        dispatch(delete_schedule_task_thunk({ token, _id: id, status: statusInp }));
    };

    const convertUtcTimeToCountryTime = (utcTime: string, timezone: string): string => {
        if (!utcTime || !timezone) {
            throw new Error("Both utcTime and timezone must be provided");
        }

        const countryTime = moment.utc(utcTime).tz(timezone);
        return countryTime.format('h:mm A'); // Format to get output like '8:00 AM'
    };
    return (
        <Box mt={4} display="flex" justifyContent="center" style={{ overflowY: 'scroll' }}>
            <style>
                {`
          /* Hide scrollbar for Chrome, Safari and Opera */
          ::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge, and Firefox */
          body {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
        `}
            </style>
            <Paper elevation={3} sx={{ p: 4, width: '80%', backgroundColor: '#f5f5f5' }}>
                <Grid container spacing={2} mb={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search by task title or Assigned to"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            sx={{ bgcolor: '#fff', borderRadius: '4px' }}
                        />
                    </Grid>
                    {/* <Grid item sm={4} >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Select A Status</InputLabel>
                            <Select
                                value={status ? status.id : scheduleTaskStatusArray[0].id}
                                onChange={(e) => {
                                    const id = e.target.value;
                                    const selectedStatus = scheduleTaskStatusArray.find((p: any) => p.id === id);
                                    setStatus(selectedStatus || null); 
                                }}
                                label="Select A Person"
                            >

                                {scheduleTaskStatusArray?.length > 0 && scheduleTaskStatusArray.map((p: any) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid> */}
                </Grid>

                <Box mt={2} className="">
                    {docs?.length > 0 ? (
                        docs
                            .filter((task: any) =>
                                task?.title?.toLowerCase().includes(searchQuery.toLowerCase()) || task?.createdFor?.username?.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .map((task: any) => (
                                // <div className='flex justify-between items-center'>

                                //     <div className='bg-gray-200 p-[10px] rounded-lg w-full'>
                                //         <div className="flex items-start">
                                //             {/* <div>
                                //                 <span className="inline-flex justify-center items-center w-6 h-6 rounded bg-green-400 text-white font-medium text-sm">
                                //                     Q
                                //                 </span>
                                //             </div> */}

                                //             <div className='flex-col justify-between w-full bg-gray-100 p-4 rounded-lg shadow-md gap-y-1'>
                                //                 <p className="font-semibold text-lg text-blue-500 ">
                                //                     Task Title: {task?.question}
                                //                 </p>
                                //                 <p className="text-gray-600 ">
                                //                     Assigned To: {task?.createdFor?.username}
                                //                 </p>
                                //                 <div className="flex justify-between text-gray-700 ">
                                //                     <span>Day: {task.day}</span>
                                //                 </div>
                                //                 <div className="flex justify-between text-gray-700 ">
                                //                     <span>Time: {convertUtcTimeToCountryTime(task?.countryTime, task?.createdFor?.timeZone)}</span>
                                //                 </div>
                                //             </div>

                                //         </div>

                                //     </div>
                                //     <div >
                                //         <IconButton onClick={(e) => handleDelete(task._id)}>
                                //             <DeleteIcon className='text-black' />
                                //         </IconButton>
                                //     </div>
                                // </div>
                                <Grid container spacing={2} key={task._id} alignItems="center">
                                    <Grid item xs={11.5} sx={{ mb: 2 }}>
                                        <Accordion key={task._id} >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel${task._id}-content`}
                                                id={`panel${task._id}-header`}
                                            >
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid item xs={10}>
                                                        <Typography variant="h6" className='space-x-4'>
                                                            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                Title : {task.title}
                                                            </span>

                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" className='space-x-4'>
                                                            <span style={{ fontSize: '14px' }}>
                                                                Assigned To: {task?.createdFor?.username}
                                                            </span>

                                                            <span style={{ fontSize: '14px' }}>
                                                                Day: {task.day}
                                                            </span>
                                                            <span style={{ fontSize: '14px' }}>
                                                                Time: {convertUtcTimeToCountryTime(task?.countryTime, task?.createdFor?.timeZone)}
                                                            </span>
                                                            <span style={{ fontSize: '14px' }}>
                                                                {task.deadlineHour ? `Deadline is ${task.deadlineHour} hour` : ''}
                                                            </span>
                                                        </Typography>
                                                    </Grid>
                                                    {/* <Grid item xs={2} style={{ textAlign: 'right' }}>
                                                        <IconButton onClick={(e) => { e.stopPropagation(); window.open(`/feedback/${task.docId}`, '_blank'); }}>
                                                            <FeedbackIcon color="primary" />
                                                        </IconButton>
                                                    </Grid> */}
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="body2" color="textSecondary">
                                                    Description : {task.question}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={.5} style={{ textAlign: 'right' }}>
                                        <IconButton onClick={(e) => handleDelete(task._id)}>
                                            <DeleteIcon className='text-black' />
                                        </IconButton>
                                    </Grid>
                                </Grid >

                            ))
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            {docs === null ? (
                                <></>
                                // <CircularProgress size={35} color="secondary" thickness={5} />
                            ) : (
                                <Typography variant="body2">No tasks found</Typography>
                            )}
                        </Box>
                    )
                    }
                </Box >
            </Paper >
        </Box >
    )
}
