import { createSlice } from "@reduxjs/toolkit";
import { create_folder_thunk, get_folders_thunk } from './folderThunk'

const initialState = {
    data: null,
    error: null,
    loading: false
}

const createFolderCases = (builder: any) => {
    builder.addCase(create_folder_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(create_folder_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
    }).addCase(create_folder_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}

const getFolderCases = (builder: any) => {
    builder.addCase(get_folders_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(get_folders_thunk.fulfilled, (state: any, action: any) => {
        state.data = action.payload.data.response;
        state.loading = false;
    }).addCase(get_folders_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}


const alertSlice = createSlice({
    name: "folder",
    initialState,
    reducers: {
        clearFolders: (state) => {
            state.data = null;
            state.error = null;
            state.loading = false
        },
    },
    extraReducers: (builder) => {
        createFolderCases(builder)
        getFolderCases(builder)
    }
})

export const { clearFolders } = alertSlice.actions;

export default alertSlice.reducer;
