import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataAPI, postDataAPI, putDataAPI } from "../../utils/FetchData";
import { setErrorAlert, setLoader, setSuccessAlert } from "../alert/alertSlice";

export const create_schedule_task_thunk: any = createAsyncThunk('admin/employee/create_schedule_task', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const body = { ...tempData };
        delete body.token;
        let response = await postDataAPI('admin/employee/create_schedule_task', body.data, tempData.token);

        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'Task created successfully'));
        thunkAPI.dispatch(get_schedule_task_thunk({ token: tempData.token }))
        thunkAPI.dispatch(setLoader(false))
        return response?.data?.data?.response
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})


export const get_schedule_task_thunk: any = createAsyncThunk('admin/employee/get_schedule_task_list', async (tempData: any, thunkAPI) => {
    try {
        // thunkAPI.dispatch(setLoader(true))
        let response = await getDataAPI('admin/employee/get_schedule_task_list', tempData.token);
        // thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})



export const delete_schedule_task_thunk: any = createAsyncThunk('admin/employee/delete_schedule_task', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const response = await putDataAPI(`admin/employee/delete_schedule_task/${tempData._id}`, {}, tempData?.token);
        await thunkAPI.dispatch(get_schedule_task_thunk({ token: tempData.token }))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'Document deleted successfully'));
        thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const get_employee_schedule_task_thunk: any = createAsyncThunk('admin/employee/get_employee_schedule_task_list', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        let response = await postDataAPI('admin/employee/get_employee_schedule_task_list', { status: tempData.status, createdFor: tempData.createdFor, assignedDateFilter: tempData.assignedDateFilter }, tempData.token);
        thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})
export const delete_employee_schedule_task_thunk: any = createAsyncThunk('admin/employee/delete_schedule_task', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const response = await putDataAPI(`admin/employee/delete_employee_schedule_task/${tempData._id}`, {}, tempData?.token);
        await thunkAPI.dispatch(get_employee_schedule_task_thunk({ status: tempData.status, token: tempData.token, createdFor: tempData.createdFor, assignedDateFilter: tempData.assignedDateFilter }))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'Document deleted successfully'));
        thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})