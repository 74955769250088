import React from 'react'
import Header from '../components/header/Header'
import Schedule from '../components/schedule/Schedule'
import ScheduleTaskComponent from '../components/schedule/ScheduleTaskComponent'

export default function ScheduleTask() {
    return (
        <>
            <Header />
            <Schedule />
            <ScheduleTaskComponent />
        </>
    )
}
