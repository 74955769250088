import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert } from '../../redux/alert/alertSlice';
import { create_folder_thunk, get_folders_thunk } from '../../redux/folder/folderThunk';
import { create_doc_thunk } from '../../redux/doc/docThunk';
import { create_email_thunk, get_emails_thunk } from '../../redux/email/emailThunk';
import moment from 'moment-timezone';
import { get_employees_thunk } from '../../redux/employee/employeeThunk';
import { create_schedule_task_thunk } from '../../redux/scheduletask/scheduleTaskThunk';
import { answeStyleVar } from '../../common/common';
import { title } from 'process';
import { create_onetime_task_thunk } from '../../redux/onetimetask/oneTimeTaskThunk';

let weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate());
// tomorrow.setDate(tomorrow.getDate() + 1);
const minDate = tomorrow.toISOString().split('T')[0];


export default function Form() {
    const token = localStorage.getItem('s3WebToken');
    const [country, setCountry] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [countryTime, setCountryTime] = useState('');
    const [usaTime, setUsaTime] = useState('');
    const [email, setEmail] = useState<any>(null);
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [answerStyle, setAnswerStyle] = useState('text'); // State to handle the answer style selection
    const [deadline, setDeadline] = useState('');


    const employees = useSelector((state: any) => state.employee.data)
    const dispatch = useDispatch();

    console.log('countryTime', countryTime)
    console.log('usaTime', usaTime)

    useEffect(() => {
        dispatch(get_employees_thunk({ token }));
    }, [dispatch, token]);

    const handleSubmit = () => {

        if (!email || taskTitle === '' || taskDescription === '' || countryTime === '' || usaTime === '' || answerStyle === '' || deadline === '') {
            dispatch(setErrorAlert('Please fill all the fields'))
            return;
        }

        const deadlineTime = convertToUTC(countryTime, deadline, timeZone);

        const newScheduleTask: any = {
            title: taskTitle,
            question: taskDescription,
            answerType: answeStyleVar[answerStyle], // Include the answer style in the task data
            email: email.email,
            countryTime: countryTime,
            usaTime: usaTime,
            deadline: deadline,
            deadlineTime: deadlineTime,
        }

        dispatch(clearAlerts());
        dispatch(create_onetime_task_thunk({ token, data: newScheduleTask }));
        setEmail(null);
        setTaskTitle('');
        setTaskDescription('');
        setCountryTime('');
        setUsaTime('');
        setDeadline('');
    };

    // Function to handle time change and convert to another time zone
    const handleIndiaTimeChange = (e: any) => {
        if (!country) {
            dispatch(setErrorAlert('Please Select a user'))
            return;
        }
        const timeValue = e.target.value;
        setCountryTime(timeValue);

        // Convert the selected time to USA time zone (assuming 'America/New_York' for Eastern Time)
        const countryTimeWithDate = moment.tz(timeValue, 'HH:mm', timeZone);
        const usaTimeWithDate = countryTimeWithDate.clone().tz('America/New_York');
        setUsaTime(usaTimeWithDate.format('HH:mm'));
    };

    // Function to handle answer style change
    const handleAnswerStyleChange = (style: string) => {
        setAnswerStyle(style);
    };


    return (
        <Box p={4} maxWidth="md" mx="auto" className="no-scrollbar">
            <Paper elevation={3} sx={{ p: 4 }} >
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Select A Email</InputLabel>
                            <Select
                                value={email ? email._id : ''}
                                onChange={(e) => {
                                    const selectedEmployeeId = e.target.value;
                                    console.log('selectedEmailId', selectedEmployeeId)
                                    const selectedEmployee = employees.find((e: any) => e._id === selectedEmployeeId);
                                    console.log('selectedEmail', selectedEmployee)
                                    setEmail(selectedEmployee || null); // Update the entire person object or reset if none selected
                                    setCountry(selectedEmployee?.country || '')
                                    setTimeZone(selectedEmployee?.timeZone || '')
                                }}
                                label="Select A Email"
                            >

                                {employees?.length > 0 && employees.map((e: any) => (
                                    <MenuItem key={e._id} value={e._id}>
                                        {e.email} ({e.country})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-multiple-chip-label">Select a Day</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={days}
                                onChange={(e) => setDays(e.target.value as string[])}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                label="Select A Email"
                            >
                                {weekDays.map((day) => (
                                    <MenuItem key={day} value={day}>
                                        <Checkbox checked={days.includes(day)} />
                                        <ListItemText primary={day} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid> */}

                    {/* <Grid container columnSpacing={15}>
                        {weekDays.map((day: any) => (
                            <Grid key={day} item xs={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={days.includes(day)}
                                            onChange={(e) => {
                                                setDays(prevDays => {
                                                    const updatedDays = e.target.checked
                                                        ? [...prevDays, day]
                                                        : prevDays.filter((d) => d !== day);
                                                    return updatedDays;
                                                });
                                            }}
                                        />
                                    }
                                    label={day}
                                />
                            </Grid>
                        ))}
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter a Task Title"
                            multiline
                            rows={1}
                            value={taskTitle}
                            onChange={e => setTaskTitle(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter a Task Description"
                            multiline
                            rows={4}
                            value={taskDescription}
                            onChange={e => setTaskDescription(e.target.value)}
                        />
                    </Grid>
                    {/* <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter a deadline in hours"
                            multiline
                            rows={1}
                            value={deadlineHour}
                            onChange={handleInputChange}
                        />
                    </Grid> */}

                    {/* Answer Style Selection */}
                    <Grid item xs={12} className='flex items-center gap-4'>

                        <div>Choose answer style: </div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={answerStyle === 'text'}
                                    onChange={() => handleAnswerStyleChange('text')}
                                />
                            }
                            label="Text"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={answerStyle === 'doc'}
                                    onChange={() => handleAnswerStyleChange('doc')}
                                />
                            }
                            label="Google Doc"
                        />

                    </Grid>

                    <Grid container item xs={12} md={12} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" alignItems="center" flexWrap="wrap">
                                <FormControl className='w-full'>
                                    <div>{country} Time</div>
                                    <TextField
                                        aria-label="Select Time (India)"
                                        fullWidth
                                        variant="outlined"
                                        type="time"
                                        inputProps={{ step: 1800 }}
                                        value={countryTime}
                                        onChange={handleIndiaTimeChange}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" alignItems="center" flexWrap="wrap">
                                <FormControl className='w-full'>
                                    <div>USA Time</div>
                                    <OutlinedInput
                                        aria-label="Select Time"
                                        type="time"
                                        inputProps={{ step: 1800 }}
                                        value={usaTime}
                                        disabled
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Select a Deadline"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: minDate,
                                }}
                                value={deadline}
                                onChange={e => setDeadline(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Box>
                </Grid>
            </Paper>
        </Box>
    )
}


function convertToUTC(time: string, date: string, timezone: string) {
    try {
        console.log('time', time);
        console.log('date', date);
        console.log('timezone', timezone);

        // Combine the date and time
        const localDateTimeString = `${date} ${time}`;

        // Parse the date and time in the specified timezone
        const localDate = moment.tz(localDateTimeString, 'YYYY-MM-DD HH:mm', timezone);

        // Convert the time to UTC
        const utcDate = localDate.utc();

        console.log("UTC Time:", utcDate.toISOString()); // Outputs the UTC time in ISO format
        return utcDate.toISOString();
    } catch (error) {
        console.error('Error converting to UTC:', error);
        return null;
    }
}