import './App.css';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode'
import PrivateRoutes from './routing/PrivateRoutes';
import Alert from './components/Alert/Alert';
import AuthRoutes from './routing/AuthRoutes';
import { logout_thunk } from './redux/auth/authThunk';
import { getDataAPI } from './utils/FetchData';
// import Alert from './components/Loader/Alert';
function App() {

  const queryParams = new URLSearchParams(window.location.search);
  let token: any = queryParams.get('token');

  if (token) {
    localStorage.setItem('s3WebToken', token);
  }

  let tokenState = localStorage.getItem('s3WebToken');
  let bool = false
  if (tokenState) {
    bool = true
  }
  const [currentUser, setCurrentUser] = useState(bool);

  const auth = useSelector((state: any) => state.auth?.isLogin);
  const dispatch = useDispatch()
  useEffect(() => {
    const token = localStorage.getItem('s3WebToken');
    const isLoggedIn: any = token;
    setCurrentUser(isLoggedIn);

    const checkTokenExpiration = async () => {
      if (token) {
        const decodedToken: any = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);

        try {
          await getDataAPI('auth/google_refresh_token', token)
        } catch (error: any) {
          console.log('error.', error)
          dispatch(logout_thunk())
          return
        }

        if (decodedToken.exp <= currentTime) {
          dispatch(logout_thunk());
        }
      }
    };

    checkTokenExpiration();
  }, [dispatch, auth]);
  return (

    <>
      <Alert />
      <Router>
        <Routes>
          {/* <Route path='/*' element={<PrivateRoutes />} /> */}

          {
            !currentUser ?
              <>
                <Route path='/*' element={<AuthRoutes />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
              :
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
          }
        </Routes>
      </Router>
    </>
  );
}

export default App;
