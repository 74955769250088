import axios from "axios"
import { setLoader } from "../redux/alert/alertSlice"
export const AWS_S3_URL = "https://0kxb9oawp3.execute-api.us-east-2.amazonaws.com"
// export const AWS_S3_URL = "http://localhost:5000"



const postDataAPIAWS = async (url: any, post: any, token: any) => {

    const res = await axios.post(`${AWS_S3_URL}/${url}`, post,
        {
            headers: { Authorization: token }
        })

    return res;
}

export const uploadAudio = async (formData: any, dispatch: any) => {
    dispatch(setLoader(true))

    const res = await postDataAPIAWS(`aws/uploadAudio`,
        formData
        , {})
    const data = res.data
    // dispatch(chatLoader(false))

    return data
}

