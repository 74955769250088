import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataAPI, postDataAPI, putDataAPI } from "../../utils/FetchData";
import { setErrorAlert, setLoader, setSuccessAlert } from "../alert/alertSlice";

export const create_doc_thunk: any = createAsyncThunk('admin/useractivity/create_googledoc', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const body = { ...tempData };
        delete body.token;
        let response = await postDataAPI('admin/doc/create_documnet', body.data, tempData.token);
        thunkAPI.dispatch(get_docs_thunk({ status: 'Pending', token: tempData.token }))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'Document created successfully'));
        thunkAPI.dispatch(setLoader(false))
        return response?.data?.data?.response
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const get_docs_thunk: any = createAsyncThunk('admin/doc/get_document_list', async (tempData: any, thunkAPI) => {
    try {
        console.log('tempData?.status', tempData?.status)
        const response = await postDataAPI(`admin/doc/get_document_list`, { status: tempData?.status }, tempData?.token);
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})


export const delete_doc_thunk: any = createAsyncThunk('admin/doc/delete_document', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))

        const response = await putDataAPI(`admin/doc/delete_document/${tempData.docId}`, {}, tempData?.token);
        await thunkAPI.dispatch(get_docs_thunk({ status: 'Pending', token: tempData.token }))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'Document deleted successfully'));
        thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const get_feedback_thunk: any = createAsyncThunk('admin/doc/get_feedback_details', async (tempData: any, thunkAPI) => {
    try {
        const response = await getDataAPI(`admin/doc/get_feedback_details/${tempData.docId}`, tempData?.token);
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const update_feedback_thunk: any = createAsyncThunk('admin/doc/update_feedback', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const response = await putDataAPI(`admin/doc/update_feedback/${tempData.docId}`, tempData.data, tempData?.token);
        await thunkAPI.dispatch(get_feedback_thunk({ docId: tempData.docId, token: tempData.token }))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'Document saved successfully'));
        thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

