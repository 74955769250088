import axios from 'axios'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const BASE_URL = BACKEND_URL;

export const postDataAPI = async (url: any, post: any, token: any) => {

    const res = await axios.post(`${BASE_URL}/${url}`, post,
        {
            headers: { Authorization: token }
        })

    return res;
}

export const getDataAPI = async (url: any, token: any) => {
    const res = await axios.get(`${BASE_URL}/${url}`,
        {
            headers: { Authorization: token }
        })

    return res;
}

export const putDataAPI = async (url: any, post: any, token: any) => {

    const res = await axios.put(`${BASE_URL}/${url}`, post,
        {
            headers: { Authorization: token }
        })

    return res;
}